<template>
  <div class="competence">
    <Header title="高新产品" index="首页" titleOne="高科模块" beforeTitle="高新产品" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-input
            v-model="psParams.company_name"
            placeholder="请输入企业名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-input
            v-model="psParams.ps_name"
            placeholder="请输入产品名称关键字"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button class="add" @click="addPS('')">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="ps.data"
        style="width: 100%"
      >
        <el-table-column prop="company_name" label="企业名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.company_name ? scope.row.company_name : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="area" label="所属区域" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.area ? scope.row.area : "-" }}</template>
        </el-table-column>
        <el-table-column prop="ps_code" label="PS编号" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.ps_code ? scope.row.ps_code : "-" }}</template>
        </el-table-column>
        <el-table-column prop="ps_name" label="产品名称" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.ps_name ? scope.row.ps_name : "-" }}</template>
        </el-table-column>
        <el-table-column prop="sales" label="上年销售收入(万元)" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{ scope.row.sales ? scope.row.sales : "-" }}</template>
        </el-table-column>
        <el-table-column prop="supporting_materials" label="证明材料" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
            scope.row.supporting_materials
            ? scope.row.supporting_materials
            : "-"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="350px">
          <template slot-scope="scope">
            <el-dropdown class="psps" trigger="click">
              <span class="el-dropdown-link">
                其他材料
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="handleEdit(scope.row.id)">技术指标</el-dropdown-item>
                <el-dropdown-item @click.native="psEdit(scope.row.id)">PS情况表</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button class="edit" size="mini" @click="psExport(scope.row.id)">导出</el-button>
            <el-button class="edit" size="mini" @click="addPS(scope.row.id)">编辑</el-button>
            <el-button class="del" size="mini" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block" v-if="ps.total > 0">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="psParams.page"
          :page-sizes="[2, 10, 20, 40]"
          :page-size="psParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="ps.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog title="导出" :visible.sync="dialogExpoet" width="20%" top="20%">
      <div style="margin: 10px 0;">材料类型</div>
      <el-select style="width: 100%;" v-model="valuePs" multiple placeholder="请选择导出材料类型/可多选">
        <el-option label="技术指标" value="1"></el-option>
        <el-option label="PS情况表" value="2"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogExpoet = false">取 消</el-button>
        <el-button type="primary" @click="exportSure()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from '../../components/global.vue'
import { mapGetters, mapState } from "vuex";
export default {
  inject: ["reload"],
  data () {
    return {
      exportUrl: url.userSite,
      ids: [],
      valuePs: '',
      dialogExpoet: false,
      loading: true,
      psParams: {
        page: 1,
        limit: 10,
        company_name: "",
        ps_name: "",
        order: "id desc",
      },
    };
  },
  activated () {
    // this.handleSearch();
    this.psParams
    this.$store.dispatch("getPSList", this.psParams);
  },
  computed: {
    ...mapState(["ps"]),
  },
  created () {
    this.$store.dispatch("getPSList", this.psParams);
  },
  methods: {
    exportSure () {
      if (this.ids && this.valuePs != '') {
        console.log(this.ids, this.valuePs)
        this.axios.get('/api/ps/export_files', {
          params: {
            ids: this.ids,
            type: this.valuePs
          }
        }).then((res) => {
          this.$message.success('导出成功')
          window.open(this.exportUrl + res.data.url)
        })
      } else {
        this.$message.warning('请选择导出类型')
      }
    },
    psExport (id) {
      let arr = []
      this.ids = [...new Set(arr += id)]
      this.dialogExpoet = true
      console.log(this.ids)
      this.valuePs = ''
    },
    handleSizeChange (val) {
      this.psParams.limit = val;
      this.$store.dispatch("getPSList", this.psParams);
    },
    handleCurrentChange (val) {
      this.psParams.page = val;
      this.$store.dispatch("getPSList", { ...this.psParams });
    },
    handleSearch () {
      this.$store.dispatch("getPSList", { ...this.psParams, page: '', limit: '' });
      this.psParams.page = 1
    },
    addPS (id) {
      this.$router.push("/ps/info?id=" + id);
    },
    handleEdit (id) {
      this.$router.push("/ps/technical?id=" + id);
    },
    psEdit (id) {
      this.$router.push("/ps/ps?id=" + id);
    },
    handleDelete (id) {
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios.delete("/api/ps/delete", {
            params: {
              id: id,
            },
          }).then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$store.dispatch("getPSList", this.psParams);
          });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  beforeUpdate () {
    this.loading = false;
  },
};
</script>
<style scoped>
div/deep/.el-table__row:hover .psps {
  background: #fff !important;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.16);
}
div/deep/.psps {
  background: #f2f3f9;
  color: #0f38ff;
  font-size: 12px;
  margin-right: 8px;
  padding: 1px 6px;
  cursor: pointer;
}
div/deep/.el-dialog__title {
  font-weight: bold !important;
}
</style>
